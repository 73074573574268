import React, { useRef, useEffect } from "react";
import styles from './styles.module.css';

const Spinner = () => {
    const circleRef = useRef<SVGCircleElement | null>(null);

    useEffect(() => {
        if (circleRef.current) {
            circleRef.current.style.setProperty(
                "filter",
                "url(#shadow)",
            );
        }
    }, []);

    return (
        <div className={styles.spincontainer} id="spincontainer">
            <svg viewBox="0 0 100 100">
                <defs>
                    <filter id="shadow">
                        <feDropShadow
                            dx="0"
                            dy="0"
                            stdDeviation="1.5"
                            floodColor="#cc0f66"
                        />
                    </filter>
                </defs>
                <circle
                    id="spinner"
                    ref={circleRef}
                    className={styles.spinner}
                    style={{
                        fill: "transparent",
                        stroke: "#cc0f66",
                        strokeWidth: "7px",
                        strokeLinecap: "round",
                    }}
                    cx="50"
                    cy="50"
                    r="45"
                />
            </svg>
        </div>
    );
};

export default Spinner;
