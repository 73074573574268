import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import styles from './styles.module.css'

export default function headerComponent() {

    const redirectToHome = () => {
        window.location.href = '/';
    };

    return (
        <>
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand>
                        <div className={styles.div} >
                            <img
                                onClick={redirectToHome}
                                alt="iDEAL logo"
                                src={require("../../assets/images/iDEAL_RGB_2020_v1_medium.png")}
                                width="60px"
                                height="53px"
                                className={styles.img}
                            />
                            <div className={styles.headertext}>Het <br /><b>Nieuwe</b> <br />iDEAL</div>
                        </div>

                    </Navbar.Brand>

                </Container>
            </Navbar>
        </>
    );
}
