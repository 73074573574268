import React from "react";
import { HomePage } from "./pages/Home";

import {
    HOME_ROUTE,
} from "./utils/constants";

export interface IRoute {
    readonly path: string;
    component: React.FC;
    keyLocalize: string;
}

export const publicRoutes: IRoute[] = [
    {
        path: HOME_ROUTE,
        component: HomePage,
        keyLocalize: "home",
    }
];
