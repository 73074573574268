
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import styles from './styles.module.css';
import sharedstyles from '../shared/shared-styles.module.css';

export const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const signature = searchParams.get('sig');
    const payloadUri = searchParams.get('payloadUri');

    function handleClick(choice: string) {
        navigate(`/loading/${choice}`, { state: { givenSignature: signature, givenpayloadUri: payloadUri } });
    }
    return (
        <>
            <Container fluid="xxl" className={styles.container}>
                <div className={styles.center}>
                    <div className={styles.span}>
                        <h1 >Which test simulation to run?</h1>
                    </div>
                    <Button
                        className={sharedstyles.button}
                        size="lg"
                        onClick={() => handleClick("SUCCESS")}
                    >Success</Button>
                    <Button
                        className={sharedstyles.button}
                        size="lg"
                        onClick={() => handleClick("CANCEL")}
                    >Cancellation</Button>
                    <Button
                        className={sharedstyles.button}
                        size="lg"
                        onClick={() => handleClick("CANCEL BEFORE LOGIN")}
                    >Cancellation before login</Button>
                    <Button
                        className={sharedstyles.button}
                        size="lg"
                        onClick={() => handleClick("TIMEOUT")}
                    >Expiration</Button>
                    <Button
                        className={sharedstyles.button}
                        size="lg"
                        onClick={() => handleClick("FAILURE")}
                    >Failure</Button>
                </div>
            </Container>
        </>
    );
};
