import { Routes, Route } from "react-router-dom";

import {
    publicRoutes,
    IRoute,
} from "../routes";
import { LoadingScreen } from "../pages/loadingScreen"
import { initAmplify } from "../utils/amplify";
import HeaderComponent from "./Header";

initAmplify();


export const Router = () => {
    return (
        <>
            <HeaderComponent />
            <main >
                <Routes>
                    {[...publicRoutes].map(
                        ({ path, component }: IRoute) => (
                            < Route path={path} element={component("", "")} key={path} />
                        )
                    )}
                    <Route path="/loading/:choice" element={<LoadingScreen />}>
                    </Route>
                </Routes>

            </main>
        </>
    );
};
